import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

// users
import user1 from "../../../assets/images/users/avatar-2.jpg";

import { connect } from "react-redux";

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return obj;
  }
}

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      name: "Admin",
      user: {},
    }
    this.toggle = this.toggle.bind(this)
    this.token = JSON.parse(localStorage.getItem("authUser")).token;
    this.interval = null;
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  componentDidMount() {
    const userData = getUserName();
    if (userData) {
      this.setState({ name: userData.username })
    }
    this.fetchAdminDetails();

    if (this.token) {
      this.interval = setInterval(() => {
        const decoded = jwtDecode(this.token);
        const expiry = decoded.exp * 1000 - 60000;

        if (Date.now() >= expiry) {
          this.handleLogout();
        }
      }, 1000);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        this.setState({ name: userData.username })
      }
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  handleLogout = () => {
    console.log("Logging out...");
    localStorage.removeItem("authUser")
    localStorage.removeItem("user")
    window.location.href = "/login";
  };

  fetchAdminDetails = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log("data: ", user);
    this.setState({ user: user });
  };
  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <div className="profile-img-div-header">
              <span className="profile-text-header"> {this.state.user.name?.split("")[0]?.toUpperCase()} </span>
            </div>
            {/* <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />{" "} */}
            <span className="d-none d-xl-inline-block ms-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile">
            <DropdownItem>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {this.props.t("Profile")}
            </DropdownItem>
          </Link>
            {/* <DropdownItem tag="a" href="/crypto-wallet">
              <i className="bx bx-wallet font-size-16 align-middle me-1" />
              {this.props.t("My Wallet")}
            </DropdownItem>
            <DropdownItem tag="a" href="#">
              <span className="badge bg-success float-end">11</span>
              <i className="bx bx-wrench font-size-17 align-middle me-1" />
              {this.props.t("Settings")}
            </DropdownItem>
            <DropdownItem tag="a" href="auth-lock-screen">
              <i className="bx bx-lock-open font-size-16 align-middle me-1" />
              {this.props.t("Lock screen")}
            </DropdownItem> */}
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string
}

const mapStateToProps = state => {
  const { success } = state.Profile
  return { success }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
)
